html {
  scrollbar-color: var(--accent) var(--background-color);
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  border-radius: 5px;
  border: 2px solid var(--background-color);
}

@mixin acatBodyGradient {
  background-image: radial-gradient(circle at 0% 0%, hsla(180, 77%, 74%, 0.35) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 20% 0%, hsla(207.79411764705884, 77%, 74%, 0.35) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 40% 0%, hsla(133.6764705882353, 77%, 74%, 0.35) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 60% 0%, hsla(127.0588235294118, 77%, 74%, 0.35) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 80% 0%, hsla(62.20588235294117, 77%, 74%, 0.35) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 100% 0%, hsla(202.5, 77%, 74%, 0.35) 3%, transparent 40%),
    radial-gradient(circle at -20% 0%, hsla(23.823529411764703, 77%, 74%, 0.35) 3%, transparent 40%),
    radial-gradient(circle at -40% 0%, hsla(62.20588235294117, 77%, 74%, 0.35) 3%, transparent 40%),
    radial-gradient(circle at -60% 0%, hsla(80.73529411764706, 77%, 74%, 0.35) 3%, transparent 40%),
    radial-gradient(circle at -80% 0%, hsla(186.61764705882354, 77%, 74%, 0.35) 3%, transparent 40%),
    radial-gradient(circle at -100% 0%, hsla(236.91176470588243, 77%, 74%, 0.35) 3%, transparent 40%),
    radial-gradient(circle at -120% 0%, hsla(295.99999999999994, 77%, 74%, 0.35) 3%, transparent 40%);
  background-blend-mode: normal, normal, normal, normal, normal, normal, normal, normal, normal, normal, normal, normal;
}

@mixin bmsBodyGradient {
  background-image: radial-gradient(
    circle at 0% 0%,
    hsl(295.99999999999994deg 77% 74% / 35%) 3.1210986267166043%,
    transparent 40%
  ),
    radial-gradient(circle at 20% 0%, hsl(236.91176470588243deg 77% 74% / 35%) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 40% 0%, hsl(186.61764705882354deg 77% 74% / 35%) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 60% 0%, hsl(127.0588235294118deg 77% 74% / 35%) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 80% 0%, hsl(62.20588235294117deg 77% 74% / 35%) 3.1210986267166043%, transparent 40%),
    radial-gradient(circle at 100% 0%, hsl(23.823529411764703deg 77% 74% / 35%) 3%, transparent 40%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
}

body {
  color: var(--foreground);
  background-color: var(--background-color);
  background-repeat: no-repeat;
  background-attachment: fixed;

  // hide svg icons sprite
  > svg:last-of-type {
    display: none;
  }

  &.debug {
    --s: 1.5rem; /* control the size (the circle diameter) */
    --c1: #ecbe13;
    --c2: #309292;
    --_g: radial-gradient(calc(var(--s) / 2), var(--c1) 97%, #0000);

    background: var(--_g), var(--_g) calc(2 * var(--s)) calc(2 * var(--s)),
      repeating-conic-gradient(from 45deg, #0000 0 25%, var(--c2) 0 50%) calc(-0.707 * var(--s)) calc(-0.707 * var(--s)),
      repeating-linear-gradient(
        135deg,
        var(--c1) calc(var(--s) / -2) calc(var(--s) / 2),
        var(--c2) 0 calc(2.328 * var(--s))
      );
    background-size: calc(4 * var(--s)) calc(4 * var(--s));
  }

  &.acat {
    @include acatBodyGradient();
  }

  &.bms {
    @include bmsBodyGradient();
  }
}
