.product {
  &__page {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: calc(var(--gap) * 2) 0;

    .product__header {
      display: flex;
      flex-direction: column;

      h1 {
        order: 0;
      }

      h2 {
        order: -1;
        font-weight: 400;
        font-variation-settings: "wght" 400;
      }

      a {
        order: 3;
        margin-inline-start: auto;
        align-self: end;
      }
    }

    main {
      @include grid;

      grid-template-columns: 1fr;
      align-items: center;

      form {
        order: 0;

        button {
          @include op.scale-down(reverse);

          animation-timing-function: #{op.$ease-squish-5};
          animation-duration: 0.7s;
        }

        &:not(.add-to-basket) {
          display: flex;
          justify-content: space-between;
          order: 5;

          @include grid-reset;
        }

        & > span {
          margin: calc(var(--gap) * 0.5) 0;
        }
      }

      p {
        order: 3;
      }

      // Preis/Info
      .product__stock {
        .price_color {
          display: block;
          color: var(--bs-success);
          font-weight: bold;
          font-variation-settings: "wght" 600;
          font-size: $h2-font-size;
        }

        .availability {
          font-weight: 600;
          font-variation-settings: "wght" 600;
        }

        &.outofstock {
          color: var(--bs-danger);
        }

        &.instock {
          color: var(--foreground-lower);
        }
      }

      .product_pod .price_color {
        margin-block-start: 0;
      }

      .es-carousel .price_color {
        font-size: $h3-font-size;
      }
    }

    // Product images
    figure {
      display: block;
      text-align: center;
      margin-block-end: calc(var(--gap) * 0.5);

      .carousel {
        --accent: var(--middleground);

        max-width: 100%;

        &-item {
          display: flex;
          justify-content: center;
        }

        &-indicators {
          gap: calc(var(--gap) * 0.2);
          justify-content: center;
          align-items: center;

          [data-bs-target] {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 100%;
            text-indent: unset;
            background-color: unset;
            margin: unset;

            img {
              max-height: 3rem;
              width: auto;
              background: var(--bs-white);
              box-shadow: op.shadow(2);
            }
          }
        }

        &-control-prev,
        &-control-next {
          background-image: none;
        }

        figcaption {
          font-size: var(--step-1);
          color: var(--foreground-lower);
        }
      }

      img {
        max-width: 100%;

        @include media-breakpoint-down(sm) {
          height: auto;
          min-height: 500px;
        }
      }
    }
  }

  // Produktinfo und Beschreibung
  &__meta {
    section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      button {
        margin-block-start: auto;
      }
    }
  }

  // Produktinfo Liste
  &__info {
    dt {
      font-weight: bold;
      font-variation-settings: "wght" 700;
    }

    dd {
      margin-block-end: var(--gap);
    }
  }

  // "Add to cart/wishlist"
  &__form {
    @include grid;
    @include grid-auto-fit;

    --col: 15rem;

    .btn-wishlist {
      grid-row: 2;
    }
  }

  // Recently viewed, recommended products
  &__grid {
    @extend .list-unstyled;
    @include grid;
    @include grid-auto-fit;

    --col: 11rem;
  }
}

.review_add,
.facets-choices {
  li {
    ul,
    ol {
      padding-inline-start: 18px;
    }

    a {
      word-break: break-word;
    }
  }
}

.add-to-basket,
.btn-wishlist {
  display: inline-block;
}

#add_review_form {
  #id_title,
  #id_body {
    max-width: 500px;
  }
}

// Image modal
// .htmx-settling img {
//   opacity: 0;opacity
// }

// .htmx-img-indicator {
//   opacity: 0;opacity
//   transition: opacity 500ms ease-in;transition
// }

// .htmx-request .htmx-img-indicator {
//   opacity: 1;opacity
// }

// .htmx-request.htmx-img-indicator {
//   opacity: 1;opacity
// }

// figure > div > img {
//   transition: opacity 300ms ease-in;transition
// }

img[data-bs-toggle="modal"] {
  cursor: pointer;
  transition: opacity 300ms ease-in;
}

#productImageModal {
  .modal {
    &-dialog {
      justify-content: center;
    }

    &-content {
      width: auto;
    }

    &-body {
      scrollbar-gutter: stable;

      @include grid;

      --gap: 0;

      grid-template-columns: 1fr;
      grid-template-areas: "image";

      img {
        justify-self: center;
        grid-area: image;

        @extend .img-fluid;
      }

      button {
        grid-area: image;
        justify-self: end;
        z-index: 1;
        background-color: var(--transparent-white);
      }
    }
  }
}
