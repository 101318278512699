// OSCAR FORMS
// -----------

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  @extend .form-control;

  font-size: unset;
}

.form-control {
  color: var(--foreground);
  border-color: var(--border-color);
  background-color: var(--background-color);
}

// [data-theme="dark"] {
//   // & form button,
//   & .btn-group button {
//     color: var(--foreground);
//     border-color: var(--border-color);
//     background-color: var(--background-color);
//   }
// }

// @media (prefers-color-scheme: dark) {
//   form button,
//   .btn-group button {
//     color: var(--foreground);
//     border-color: var(--border-color);
//     background-color: var(--background-color);
//   }
// }

// For IE: adds width for selects inside overflow hidden containers
.select-open {
  min-width: 300px !important;
}

// Additional errors
.errorlist {
  margin: 0;
  padding: 0 0 10px;
  color: $red;

  li {
    padding: 0 0 5px;
    list-style: none;
  }
}

.error-block {
  color: $red;
  margin-block-start: 6px;
}

.col-form-label.required::after {
  color: $red;
  content: "*";
  display: inline-block;
}

// Star rating functionality for product review page
.reviewrating {
  select {
    display: none;
  }

  .star-rating {
    margin-block-end: 10px;
    font-size: 200%;

    @include media-breakpoint-up(sm) {
      font-size: 250%;
    }

    .icon {
      cursor: pointer;
    }
  }
}

// .form-actions {
//   margin: $base-margin-block-end 0;
//   padding: $base-margin-block-end;
//   background-color: $form-actions-bg-color;
//   border-block-start: solid 1px $form-actions-border-color;
// }

// Zahlungart wählen Formular
.payment-methods {
  form {
    ul {
      @extend .list-unstyled;
    }
  }
}

// login/register page
#login {
  .nav-pills {
    align-items: flex-end;

    .nav-link.active {
      background-color: transparent;
      color: var(--foreground);
      font-weight: bold;
      font-variation-settings: "wght" 600;
      border-block-end: 3px solid var(--foreground);
      border-radius: 0;
    }
  }

  form {
    box-shadow: op.shadow(2);
  }
}

// password reset form
#id_email {
  margin-block-end: var(--gap);
}

// navbar search
.navbar {
  .sitesearch {
    input[type="search"] {
      display: none; // TODO @KJ: wird erweitert als Dropdown

      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  }
}

// Spalten in Formularen
form {
  .col-md {
    margin-bottom: 0.2rem;
  }
}
