/*
Hanken Grotesk
https://fonts.google.com/specimen/Hanken+Grotesk
https://www.npmjs.com/package/@fontsource/hanken-grotesk
https://fontsource.org/fonts/hanken-grotesk
*/

// Hanken Grotesk Variable font
// https://fontsource.org/docs/getting-started/sass-import
@include HankenGrotesk.faces(
  $subsets: (
    cyrillic-ext,
    latin,
    latin-ext,
    vietnamese,
  ),
  $weights: (
    400,
    500,
    600,
    700,
  ),
  $styles: all,
  $directory: "../font/"
);

$modern-fonts-stack: avenir, "Avenir Next LT Pro", montserrat, corbel, "URW Gothic", source-sans-pro, $font-family-base; // https://modernfontstacks.com/

body {
  font-size: var(--step-0);
  font-family: "Hanken Grotesk Variable", $modern-fonts-stack;
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-optical-sizing: auto;
  font-weight: 400;
}

@supports (font-variation-settings: normal) {
  body {
    font-variation-settings: "wght" 400;
    font-feature-settings: "calt", "kern", "dlig";
    font-synthesis: none;
  }
}

h1,
h2,
article > header,
a[href^="mailto:"] {
  hyphens: none;
}

p,
dt,
dd,
li {
  hyphens: auto;
}

h1,
.btn-outline,
.nav-checkout .active span {
  font-variation-settings: "wght" 700;
}

h2,
h3,
b,
strong {
  font-variation-settings: "wght" 600;
}

h4,
h5,
h6 {
  font-variation-settings: "wght" 500;
}

em,
i {
  font-style: italic;
}

sup {
  font-feature-settings: "sups";
}

sub {
  font-feature-settings: "subs";
}

h1 {
  font-size: var(--step-5);
}

h2 {
  font-size: var(--step-4);
}

h3 {
  font-size: var(--step-3);
}

h4 {
  font-size: var(--step-2);
}

h5 {
  font-size: var(--step-1);
}

h6 {
  font-size: var(--step-0);
}

@supports (text-wrap: balance) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote {
    text-wrap: balance;
  }
}
