.basket {
  &-page {
    #content,
    #content_inner {
      @include grid;
    }
  }

  // mini basket dropdown
  &-mini {
    .dropdown-menu {
      font-feature-settings: "tnum";
      min-width: 30rem;
      padding: calc(var(--gap) * 0.5);
      box-shadow: op.shadow(2);
      max-height: 75vh;
      overflow: hidden auto;
    }

    &-item {
      @extend .list-unstyled;

      display: flex;
      flex-direction: column;
      gap: calc(var(--gap) * 0.5);
    }
  }

  &-items {
    padding: calc(var(--gap) * 0.5) 0;

    .btn.float-right {
      @extend .btn-outline-secondary;
    }

    &:nth-child(odd) {
      background-color: var(--background-alternate);
    }
  }

  // cart
  &_summary {
    @include grid;
  }

  // Badge with item count
  &--count {
    @extend .badge;
    @extend .badge-warning;
    @extend .rounded-pill;
    @extend .translate-middle;

    position: absolute;
    top: unset;
  }
}

// saved for later
#saved_basket_formset {
  @include grid;
}

// checkout
#new_shipping_address {
  .form-group {
    margin-block-end: var(--gap);
  }
}

// shipping
.shipping {
  &-methods {
    main {
      @include flex-column;

      gap: var(--gap);

      button {
        width: 100%;
      }
    }
  }
}
