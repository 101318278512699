:root {
  --accent: #{op.$indigo-7};
  --link: #{op.$indigo-10};
  --link-higher: #{op.$indigo-8};
  --logo: url("../img/acat/logo.svg");
  --logo-neg: url("../img/acat/logo-neg.svg");
  --bs-primary: #{op.$indigo-10};
}

// sass vars
$logo: "../img/acat-logo.svg";
$logo-neg: "../img/acat-logo-neg.svg";
$navbar-button-text: true;
$primary: hsl(230deg 57.3% 43.1%); // Open Props indigo-10
