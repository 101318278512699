// Open Props pager
// https://codepen.io/argyleink/pen/QWMreXR
.pagination {
  & > li > a {
    text-decoration: none;
    outline-offset: 3px;
    transition: background-color 0.2s var(--ease-3), color 0.2s var(--ease-3);
    border-radius: var(--radius-1);

    &:not([href]) {
      cursor: not-allowed;

      --_link-bg: transparent;
    }
  }
}
