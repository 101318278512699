// Checkout
.nav-checkout {
  .active {
    span {
      color: #fff;
    }
  }

  .nav-link {
    padding: unset;
  }
}

.checkout {
  .shipping-address {
  }

  address {
    margin-bottom: 0;
  }
}

// Payment
.payment_methods {
  ul {
    @include grid;
    @include grid-auto;

    li {
      // input {
      //   @extend .btn-check;
      // }

      label {
        width: 100%;

        @extend .btn;
        @extend .btn-lg;
        @extend .btn-outline-secondary;

        // & + input:checked {
        //   @extend .btn-secondary;
        // }
      }
    }
  }
}
