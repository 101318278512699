.modal {
  &-backdrop {
    @supports (backdrop-filter: blur()) {
      @include backdrop;

      &.show,
      &.fade {
        opacity: unset;
      }
    }
  }
}
