.img--banner {
  width: 100%;
  height: auto;
}

.banner_image {
  display: grid;
  grid-template-areas: "banner";
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;

  h1,
  img {
    grid-area: banner;
  }

  h1 {
    z-index: 2;
    margin-block-end: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.aktion {
  position: relative;
  transform-style: preserve-3d;
  border-radius: 1rem;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    transform: translate3d(0, 0, -1px);
    border-radius: inherit;
    pointer-events: none;
  }

  &--easter {
    &::before {
      background: conic-gradient(
        from 295deg at 50% -15%,
        hsl(60deg 100% 50%),
        hsl(101deg 72% 69%),
        hsl(110deg 67% 82%),
        hsl(51deg 81% 60%),
        hsl(51deg 72% 69%),
        hsl(60deg 100% 50%),
        hsl(135deg 41% 60%),
        hsl(51deg 81% 60%),
        hsl(34deg 100% 65%)
      );
      filter: blur(5rem);
    }
  }

  &--pfingsten {
    &::before {
      inset: -1rem;
      background: conic-gradient(
        from 90deg at 40% -25%,
        #ffd700,
        #f79d03,
        #07dfee,
        #0ad7e6,
        #0dde98,
        #1097d6,
        #1293cf,
        #155cc7,
        #71b4ff,
        #109ed6,
        #0d91de,
        #eecb07,
        #f79d03,
        #ffd700,
        #ffd700,
        #ffd700
      );
      filter: blur(2rem);
    }
  }
}
