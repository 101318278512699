@media print {
  @page {
    size: a4;

    // WeasyPrint:
    // @bottom-center {
    //   content: "Seite " counter(page);
    //   margin-block-end: 1cm;
    //   font-size: 7pt;
    //   font-family: sans-serif;
    // }
  }

  // no fancy stuff
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: black !important;
    filter: none !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }

  :root {
    background-color: white;
    color: black;
  }

  html,
  body,
  .page {
    height: unset;
  }

  body {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 11pt;
    margin-block-start: 0;

    & > header {
      margin-block-end: 1cm;
    }
  }

  // hide stuff
  .navbar,
  body > header,
  body > footer,
  .sidebar,
  .thank-you .page .form-group,
  .product__form,
  .product__page section:last-of-type,
  .content > nav,
  .content > article > footer,
  .pagination,
  details {
    display: none;
    visibility: hidden;
  }

  // try not to break stuff
  .basket-items,
  img,
  article,
  tr,
  td,
  dt,
  dd {
    break-inside: avoid-page;
  }

  // print minimal stuff
  .page {
    border: none;
  }

  // products in full width
  #products {
    width: 100% !important;
  }

  h1 {
    font-size: 13pt;
    font-weight: 700;
  }

  address {
    font-style: normal;
  }

  th,
  dt,
  strong,
  b {
    font-weight: 600;
  }

  em,
  i {
    font-style: italic;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
