// https://github.com/mayank99/open-props-scss#readme

:root {
  --gap: clamp(0.7rem, 5vw, 1.2rem);
  --col: 15rem;
  --font-family: var(--font-sans);
  --animation-duration: 1s;
  --loader-begin: 1.5s;
  --loader-duration: 3s;
  --navbar-duration: 200ms;

  // Icons
  --icon-size: #{op.$size-4};
  --icon-size-lg: #{op.$size-6};
  --icon-size-xl: #{op.$size-7};

  // color-scheme: light dark;
  color-scheme: light;

  // https://web.dev/accent-color/
  accent-color: var(--accent);

  --transparent-white: rgb(255 255 255 / 60%);
  --border-color: #{op.$gray-3};
  --background-color: hsl(204deg 0% 100% / 100%);
  --background-content: var(--background-color);
  --background-alternate: #{op.$gray-1};
  --foreground: #{op.$gray-8};
  --foreground-higher: #{op.$gray-9};
  --foreground-lower: #{op.$gray-7};
  --middleground: #{op.$gray-4};
  --background-higher: #{op.$stone-0};

  // navbar
  --navbar-background-color: var(--transparent-white);
  --bs-offcanvas-width: 30ch;

  // cookie consent
  --cc-font-family: "Hanken Grotesk Variable", $modern-fonts-stack !important;
}

// utopia-core-scss
// https://utopia.fyi/type/calculator?c=320,16,1.2,1240,18,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
:root {
  @include utopia.generateTypeScale(
    (
      "minWidth": 320,
      "maxWidth": 1240,
      "minFontSize": 16,
      "maxFontSize": 18,
      "minTypeScale": 1.067,
      "maxTypeScale": 1.1,
      "positiveSteps": 5,
      "negativeSteps": 2,
      "prefix": "step-",
    )
  );
}
