html,
body {
  height: 100%;
}

body {
  --content: min(120ch, 100% - var(--gap) * 2);
  --full: minmax(var(--gap), 1fr);

  display: grid;
  gap: unset;

  // grid-template-columns:
  //   [full-start] var(--full)
  //   [content-start] var(--content) [content-end]
  //   var(--full) [full-end];
  grid-template-columns: minmax(0,1fr);
  grid-template-rows: auto 1fr auto;
  padding-block-start: calc(var(--gap) * 5);
  scroll-margin-top: calc(var(--gap) * 5);
  scrollbar-gutter: stable;

  @include media-breakpoint-up(xl) {
    padding-block-start: calc(var(--gap) * 4);
    scroll-margin-top: calc(var(--gap) * 4);
  }

  // & > * {
  //   // grid-column: content;
  //   @extend .container-xl;
  // }

  // > nav, > footer  {
  //   grid-column: full;
  // }

  // Checkout
  &[class^="checkout"] {
    grid-template-rows: auto auto 1fr auto;
    padding-block-start: var(--gap);

    // > nav, > footer  {
    //   grid-column: content;
    // }
  }
}

.page {
  @include flex-column;

  gap: var(--gap);
  height: 100%; 
  box-shadow: op.shadow(2);
  background-color: var(--background-content);
  border-radius: #{op.$radius-2};
  padding-block: 15px;
}

.content {
  @include flex-column;

  gap: var(--gap);
}
