.products {
  &--list {
    @include grid;
    @include grid-auto;

    @include media-breakpoint-up(sm) {
      gap: calc(var(--gap) * 2) var(--gap);
    }

    width: 100%;
    --col: 12rem;

    justify-items: center;
    justify-self: center;

    // scroll-snap-type: y mandatory;scroll-snap-type
    // overscroll-behavior: contain;overscroll-behavior
    // overflow: auto;overflow

    &>nav {
      // pagination reset
      @include grid-reset;

      ul {
        gap: var(--gap);
      }
    }
  }

  // Startseite: Slider
  &--carousel {
    .carousel {

      &-control-prev,
      &-control-next {
        background-image: none;
      }

      &-item {
        img {
          @include img-fluid;

          max-height: 45vh;
        }
      }
    }
  }
}

.content {

  // Produkte in horiz. scrollbaren Bereich
  &--h-scroll {
    max-width: 100%;

    header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: calc(var(--gap) * 0.5);

      h3 {
        margin-bottom: 0;
      }

      nav {

        // scrolling arrows
        @at-root .no-js & {
          display: none;
        }

        display: flex;
        flex-wrap: nowrap;
        gap: 0;
      }
    }

    &>ul {
      @include grid;
      @include grid-auto;

      --col: 210px;

      grid-auto-flow: column;
      gap: calc(var(--gap) * 2);
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      overscroll-behavior-x: contain;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: 5px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      li {
        display: inline-flex;
        scroll-snap-align: center;
        min-width: var(--col);
      }

      max-width: 100%;
      list-style-type: "";
      margin: 0;
      padding: 0;

      >li {
        margin-block-end: calc(var(--gap) * 2);
      }
    }
  }
}

// Kategoriename/Produkttitel, Bearbeitungslink
.page__header {
  @include flex;

  h1 {
    margin-block-end: 0;
  }

  a {
    order: 2;
    margin-inline-start: auto;
    align-self: center;
  }
}

// Product <main>
.product {
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    justify-self: center;
    text-align: center;
    gap: calc(var(--gap) * 0.5);

    // margin: 5px;margin
    padding: var(--gap);
    scroll-snap-align: start;
    scroll-margin-top: calc(var(--gap) * 4);
    transition: all $transition-time cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: transform, opacity;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: op.shadow(3);
      opacity: 0;
      transition: all $transition-time cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      z-index: 1;

      &::after {
        opacity: 1;
      }
    }

    header {
      margin-block-end: auto;
      margin-inline: auto;

      h4 {
        font-weight: 350;
        font-variation-settings: "wght" 350;
      }
    }

    p {
      margin-block-end: 0;
    }

    h3,
    h4,
    h5 {
      line-height: 1.1;

      // hard break long words in titles
      word-break: break-word;
    }

    a {
      text-decoration: none;
    }

    // Preis, Mindbest, Verfügbarkeit <main>
    &_price {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $base-margin-block-end / 4;
    }

    .price_color {
      font-variation-settings: "wght" 550;
    }

    .availability,
    &_color {
      white-space: nowrap;
    }

    // Bild
    .image_container {
      width: 100%;

      // @include media-breakpoint-up(md) {
      //   aspect-ratio: 1;
      // }

      img {
        max-width: 100%;
        margin: auto;
      }
    }
  }
}

// facets
.facets {
  margin-bottom: 0;
}

details {
  box-shadow: op.shadow(1);
  margin-block-end: calc(var(--gap) * 2);

  summary {
    padding-inline-start: 24px;

    &::marker,
    &::-webkit-details-marker {
      display: none;
      content: "";
    }

    &::before {
      content: "";
      background: transparent url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-chevron-right'><polyline points='9 18 15 12 9 6'/></svg>") 50% 50% no-repeat;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 1rem;
      left: 0.5rem;
      transform: rotate(0);
      transform-origin: 50% 50%;
      transition: 0.2s transform ease;
    }
  }

  &[open]>summary::before {
    transform: rotate(90deg);
  }

  dl {
    &>div {
      @include grid;
      @include grid-auto;

      --col: 11rem;

      gap: 0.2rem 0.4rem;
      grid-auto-flow: dense;

      dd {
        hyphens: auto;
      }
    }

    &>dt {
      margin-block-start: var(--gap);
      font-weight: 550;
      font-variation-settings: "wght" 550;
    }
  }
}