// Bootstrap 4 -> 5

// css grid https://getbootstrap.com/docs/5.2/layout/css-grid/
// .row {
//   @extend .grid;
// }

// @for $i from 1 to 12 {
//   .col-#{$i} {
//     @extend .g-col-#{$i};
//   }
// }

// @for $i from 1 to 12 {
//   .col-sm-#{$i} {
//     @extend .g-col-sm-#{$i};
//   }
// }

// @for $i from 1 to 12 {
//   .col-md-#{$i} {
//     @extend .g-col-md-#{$i};
//   }
// }

// @for $i from 1 to 12 {
//   .col-lg-#{$i} {
//     @extend .g-col-lg-#{$i};
//   }
// }

.sr-only {
  @include visually-hidden;
}

.sr-only-focusable {
  @include visually-hidden-focusable;
}

.ml-auto {
  @extend .ms-auto;
}

.mr-auto {
  @extend .me-auto;
}

.pull-left,
.float-left {
  @extend .float-start;

  ol & {
    margin-inline-end: auto;
  }
}

.pull-right,
.float-right {
  @extend .float-end;

  ol & {
    margin-inline-start: auto;
  }
}

.text-left {
  @extend .text-start;
}

.text-right {
  @extend .text-end;
}

// Badges
.badge {
  &-default {
    @extend .text-bg-primary;
  }

  &-secondary {
    @extend .text-bg-secondary;
  }

  &-danger {
    @extend .text-bg-danger;
  }

  &-warning {
    @extend .text-bg-warning;
  }

  &-info {
    @extend .text-bg-info;
  }

  &-success {
    @extend .text-bg-success;
  }
}
