@media not (prefers-reduced-motion: reduce) {
  @view-transition {
    navigation: auto;
  }
}

// @media not (prefers-reduced-motion: reduce) {
//   .page {
//     view-transition-name: page;
//   }

//   ::view-transition-old(page) {
//     animation: slide-out 0.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
//   }

//   ::view-transition-new(page) {
//     animation: slide-in 0.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
//   }

//   @keyframes slide-out {
//     0% {
//       transform: translateX(0%);
//     }

//     100% {
//       opacity: 0;
//       transform: translateX(calc(50% * var(--direction, 1)));
//     }
//   }

//   @keyframes slide-in {
//     0% {
//       opacity: 0;
//       transform: translateX(calc(-50% * var(--direction, 1)));
//     }

//     100% {
//       transform: translateX(0%);
//     }
//   }
// }