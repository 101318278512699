// Startseite
.categories-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: calc(var(--gap) * 0.5);
  margin-block-end: calc(var(--gap) * 0.5);

  &:hover .nav-link:not(:hover) {
    color: #{op.$gray-5};
  }

  .nav-link {
    font-weight: 700;
    font-variation-settings: "wght" 700;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    color: var(--foreground-lower);
  }
}

.subcategories-nav {
  margin-block-end: 0;

  &:hover ul li:not(:hover) {
    opacity: 0.7;
  }

  ul {
    --col: 20rem;

    padding: 0;
    margin: 0;

    // Kategorien als Flächen mit Verläufern
    $gradients: #{op.$gradient-10}, #{op.$gradient-2}, #{op.$gradient-4}, #{op.$gradient-21}, #{op.$gradient-7},
      #{op.$gradient-11};
    // Kategorien-Icons mit Gradient-Masks
    $mask-icons: book-open-text, gamepad-2, shell, telescope, flask-conical, orbit;

    li {
      border: 0.4rem solid;
      border-image-slice: 1;
      gap: calc(var(--gap) * 0.5);
      display: inline-flex;
      place-content: center;
      align-items: center;
      text-align: center;
      list-style: none;
      margin: 0;
      padding: calc(var(--gap) * 2);
      position: relative;
      font-weight: 600;
      font-variation-settings: "wght" 600;

      @for $i from 1 through length($gradients) {
        &:nth-child(#{length($gradients)}n + #{$i}) {
          border-image-source: nth($gradients, $i);
        }
        &:nth-child(#{length($gradients)}n + #{$i})::before {
          background-image: nth($gradients, $i);
        }
      }
      @for $i from 1 through length($mask-icons) {
        &:nth-child(#{length($mask-icons)}n + #{$i})::before {
          mask-image: url("../img/icons/" + nth($mask-icons, $i) + ".svg");
        }
      }
      &::before {
        display: block;
        content: "";
        width: var(--icon-size-xl);
        height: var(--icon-size-xl);
        // mask-image: url("../img/icons/book-open.svg");
        mask-repeat: no-repeat;
        mask-position: 50% 50%;
      }

      a {
        text-decoration: none;
        // text-transform: uppercase;
        font-weight: 600;
        font-variation-settings: "wght" 600;

        @extend .stretched-link;
        @extend .h2;

        margin-block-end: 0.3rem;

        &:hover {
          @include boujee-text;
        }
      }
    }
  }
}
