.brand {
  display: flex;
  margin-inline-end: auto;

  h1 {
    padding: 0;
    margin: 0;
    display: inline-block;

    // Logo
    a {
      display: block;
      text-indent: -600%;
      background: transparent var(--logo) 50% 50% no-repeat;
      background-size: contain;
      overflow: hidden;
      margin: 0;
      width: min-content;

      // // themes
      // [data-theme="light"] & {
      //   background-image: url(../img/acat-logo.svg);
      // }

      // @media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
      //   background-image: url(../img/acat-logo.svg);
      // }

      // [data-theme="dark"] &,
      // [data-theme="high"] & {
      //   background-image: url(../img/acat-logo-inverted.svg);
      // }

      // @media (prefers-color-scheme: dark) {
      //   background-image: url(../img/acat-logo-inverted.svg);
      // }

      // @media (prefers-contrast: high) {
      //   background-image: url(../img/acat-logo-inverted.svg);
      // }
    }
  }

  h2 {
    font-size: var(--step-1);
  }
}

nav.navbar {
  &-custom {
    @extend .navbar-light;

    background-color: var(--navbar-background-color);

    &.active {
      background-color: var(--background-color);
    }

    > .container-fluid {
      gap: var(--gap) calc(var(--gap) * 0.5);
      justify-content: space-around;
    }

    .navbar-nav {
      gap: var(--gap) calc(var(--gap) * 0.5);
    }

    .nav-item {
      a,
      & form {
        display: flex;
        align-items: center;
        gap: 0.5cap;
        flex-flow: column;
        text-align: center;
        max-width: 10rem;

        @include media-breakpoint-up(xl) {
          flex-flow: row;
          max-width: unset;
        }
      }

      form {
        select {
          border: none;
          background-color: transparent;
          font-size: var(--step-0);
          padding-block: unset;
        }

        label {
          display: flex;
        }
      }
    }

    .nav-link {
      color: var(--foreground) !important;
      font-size: var(--step-0);

      // #900 BMS-spezifisch
      .bms & {
        @include media-breakpoint-up(lg) {
          span {
            // recreating .visually-hidden, as media queries in scss don't allow @extend
            clip: rect(0, 0, 0, 0) !important;
            border: 0 !important;
            height: 1px !important;
            margin: -1px !important;
            overflow: hidden !important;
            padding: 0 !important;
            white-space: nowrap !important;
            width: 1px !important;
          }
        }

        .icon {
          margin-inline-end: calc(var(--gap) * 0.5);
        }
      }
    }

    // SVG nav buttons
    .navbar-toggler {
      border: none;
      padding: var(--size-1);
      order: 3;

      &:focus {
        box-shadow: none;
      }
    }

    // shadow on scroll
    &.active {
      box-shadow: op.shadow(2);
      transition-duration: var(--navbar-duration);
    }

    // forms layout
    form {
      align-items: center;
    }
  }

  // side categories menu
  aside & {
    padding-block-start: 0;

    .navbar-nav-scroll {
      max-height: unset;
    }
  }
}

// Themes
// [data-theme="light"] {
//   .navbar-custom {
//     @extend .navbar-light;
//   }
// }

// [data-theme="dark"],
// [data-theme="high"] {
//   .navbar-custom {
//     @extend .navbar-dark;
//   }
// }

// Navs
nav,
.nav {
  .nav-link {
    color: var(--link);
  }

  // Basket icon
  .btn-link {
    text-decoration: none;
  }
}

.nav {
  // Bestellung-Ablauf
  &-checkout {
    border-radius: var(--radius-2);

    .nav-link {
      color: var(--middleground);
    }
  }
}

.offcanvas {
  gap: var(--gap) calc(var(--gap) * 0.5);

  &-header {
    justify-content: space-between;
  }

  &-body {
    gap: var(--gap) calc(var(--gap) * 0.5);
  }

  &-backdrop.show {
    @supports (backdrop-filter: blur()) {
      @include backdrop;
    }
  }
}

// Breadcrumbs
.breadcrumb {
  .active {
    color: var(--foreground);
  }
}

// Temp Banner
.temp-alert {
  border-radius: 0;
  margin: 0;
  padding: calc(var(--gap) * 0.3) var(--gap);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}
