// Review styles
// -------------

.review {
  margin-block-end: $base-margin-block-end;

  @include clearfix;

  h3 {
    margin-block-end: $base-margin-block-end;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Vote forms inline with "Is this review helpful?"
  form {
    display: inline-block;
    margin-block-end: 0;
  }
}

// Star Rating using svg icons for 5 stars
.star-rating {
  // writing a review
  .reviewrating & {
    &:hover {
      .star {
        fill: var(--bs-yellow) !important;
      }
    }

    .star {
      fill: none;

      &:hover ~ .star {
        fill: none !important;
      }
    }

    &[data-stars] {
      .star {
        fill: var(--bs-yellow);
      }
    }

    &[data-stars="0"] {
      .star {
        fill: none;
      }
    }

    &[data-stars="1"] {
      .star:nth-child(1) ~ .star {
        fill: none;
      }
    }

    &[data-stars="2"] {
      .star:nth-child(2) ~ .star {
        fill: none;
      }
    }

    &[data-stars="3"] {
      .star:nth-child(3) ~ .star {
        fill: none;
      }
    }

    &[data-stars="4"] {
      .star:nth-child(4) ~ .star {
        fill: none;
      }
    }

    &[data-stars="5"] {
      .star:nth-child(5) ~ .star {
        fill: none;
      }
    }
  }

  // Bootstrap js validation
  .was-validated & {
    .icon {
      stroke: var(--bs-danger);
    }
  }

  display: inline-flex;
  gap: calc(var(--gap) * 0.3);
  align-items: center;
  justify-content: flex-start;

  .product__item &,
  .product__grid &,
  .content--list & {
    justify-content: center;
  }

  // Oscar rating, rated products
  .icon {
    stroke: var(--foreground-lower);
    stroke-width: 1;
  }

  &.One .icon:first-child,
  &.Two .icon:nth-child(-n + 2),
  &.Three .icon:nth-child(-n + 3),
  &.Four .icon:nth-child(-n + 4),
  &.Five .icon:nth-child(-n + 5) {
    fill: var(--bs-yellow);
    stroke: var(--bs-yellow);
  }

  // in a dropdown menu link
  .dropdown-item:has(&) {
    display: flex;
    gap: var(--gap);
  }
}

// Rezensionen auf der Startseite
.content {
  &--recensions {
    article > div {
      @include media-breakpoint-up(md) {
        @include grid;
      }

      padding-inline: 12vw;
      padding-block: var(--gap);
      grid-template-columns: minmax(150px, 300px) 1fr;

      // https://codepen.io/t_afif/pen/qBJyXNy
      img {
        --f: 0.05; /* the parallax factor (the smaller the better) */
        --r: 5px; /* radius */
        --_f: calc(100% * var(--f) / (1 + var(--f)));
        --_a: calc(90deg * var(--f));

        // aspect-ratio: calc(1 + var(--f));
        padding: calc(var(--gap) * 1.5);
        object-fit: cover;
        clip-path: inset(0 var(--_f) 0 0 round var(--r));
        transform: perspective(400px) var(--_t, rotateY(var(--_a)));
        transition: 0.5s;
        cursor: pointer;

        &:hover {
          clip-path: inset(0 0 0 var(--_f) round var(--r));

          --_t: translateX(calc(-1 * var(--_f))) rotateY(calc(-1 * var(--_a)));
        }
      }
    }
  }
}
