// Blur backdrop
@mixin backdrop() {
  opacity: 1;
  backdrop-filter: blur(5px);
  background-color: rgb(0 0 0 / 30%);
}

// Animated gradient
// https://codepen.io/web-dot-dev/pen/vYrGPNE
@mixin boujee-text() {
  @supports (background-clip: text) {
    --bg-size: 400%;
    --color-one: var(--accent);
    --color-two: hsl(228deg 96% 79%);

    background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: move-bg 8s linear infinite;
  }
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}

// Gap
$gap: (0.2rem, 0.3rem, 0.5rem, 0.7rem, 1rem, 1.2rem);

@for $i from 1 through 6 {
  .gap-#{$i} {
    gap: nth($gap, $i);
  }
}
