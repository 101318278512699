.hx-loader {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1060;
  width: 100%;
  height: 100%;
  visibility: hidden;

  svg {
    max-width: 10rem;
    stroke: var(--accent);
  }
}

.htmx-request.hx-loader {
  visibility: visible;
}

// a11y
@media (prefers-reduced-motion: reduce) {
  :root {
    --loader-begin: 3s;
    --loader-duration: 6s;
  }
}
