// Bootstrap
:root {
  --bs-body-color: var(--foreground);
  --bs-breadcrumb-item-active-color: var(--foreground);
  --bs-dropdown-bg: var(--background-color);
  --bs-dropdown-color: var(--foreground);
  --bs-dropdown-link-color: var(--link);
  --bs-link-color: var(--foreground);
  --bs-modal-bg: var(--background-color);
  --bs-nav-link-color: var(--link);
  --bs-nav-pills-link-active-bg: var(--middleground);
  --bs-pagination-border-color: transparent;
  --bs-pagination-color: var(--link);
  --bs-scroll-height: 95vh;
  --bs-table-color: var(--foreground);
  --bs-table-striped-color: var(--middleground);
  --bs-toast-spacing: var(--gap);
}

$accent: var(--accent);
$base-margin-block-end: var(--gap) !default;
$border-radius: 0.15rem;
$btn-close-color: var(--foreground);
$btn-white-space: nowrap;
$card-bg: var(--background);
$card-header-bg: var(--background);
$carousel-control-color: $accent;
$carousel-control-color: var(--accent);
$carousel-control-transition: transform 2s ease, opacity 0.5s ease-out;
$dropdown-bg: var(--background-color);
$dropdown-color: var(--foreground);
$form-actions-bg-color: var(--background);
$form-actions-border-color: var(--border-color);
$image-container-height: 200px; // Image height
$line-height-base: 1.5 !default;
$modal-content-bg: var(--background-color);
$navbar-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#ccc' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$offcanvas-bg-color: var(--background-color);
$offcanvas-color: var(--foreground);
$paragraph-margin-block-end: var(--gap) !default;
$rated-star-color: var(--bs-warning);

// Transitions
$transition-time: 0.3s;
$transition: $transition-time ease-out;
$transition-quick: 0.2s ease;

// list-group
$list-group-color: var(--foreground);
$list-group-bg: transparent;
$list-group-border-color: rgba(#000, 0.125) !default;
$list-group-hover-bg: var(--background-color);
$list-group-action-hover-bg: var(--background-color);
$list-group-action-hover-color: var(--background);
$list-group-action-active-color: var(--foreground);
$list-group-action-active-bg: var(--background-content);

// pagination
$pagination-color: var(--foreground);
$pagination-bg: var(--background-alternate);
$pagination-border-width: 1;

// $pagination-border-color: transparent;
$pagination-focus-bg: var(--bs-gray-200);
$pagination-hover-bg: var(--link);
$pagination-hover-color: var(--bs-white);
$pagination-hover-border-color: var(--bs-gray-300);
$pagination-disabled-color: var(--foreground-lower);
$pagination-disabled-bg: var(--background);

// toasts
$toast-color: var(--foreground-lower);
$toast-header-color: var(--foreground-higher);
$toast-background-color: var(--background-color);
$toast-header-background-color: var(--background-color);
$toast-header-border-color: var(--background-content);
$toast-spacing: var(--gap);

// $toast-background-color: hsl(var(--background-color) / 85%);
