// Buttons
.btn {
  // more info w/ icon
  &-outline {
    color: var(--foreground);
    background-color: var(--background-color);
    border-color: var(--border-color);

    --transition-time-1: 0.3s;
    --transition-time-2: 200ms;

    &:hover {
      color: var(--foreground);
      background-color: var(--background-content);
      border-color: var(--link);

      svg {
        margin-inline-end: calc(var(--gap) * 0.5);
        transition: var(--transition-time-1);
      }
    }

    &:not(:hover) svg {
      transition: var(--transition-time-1);
    }

    &:active {
      transform: scale(0.9, 0.9);
      transition: var(--transition-time-2);
    }

    &:not(:active) {
      transition: var(--transition-time-2);
    }
  }

  // PayPal button
  &-paypal {
    @extend .btn-warning;

    img {
      height: 1.5rem;
      width: auto;
    }

    & > small {
      font-size: 80%;
    }
  }
}

// dark theme
// @media (prefers-color-scheme: dark) {
//   .btn-outline-primary {
//     color: var(--link);
//     border-color: var(--link);
//   }

//   .btn-outline-secondary {
//     color: var(--foreground);
//   }
// }

// [data-theme="dark"] {
//   .btn-outline-primary {
//     color: var(--link);
//     border-color: var(--link);
//   }

//   .btn-outline-secondary {
//     color: var(--foreground);
//   }
// }

// "Edit" links
h3 > a.float-right {
  color: var(--stone-7);
}
