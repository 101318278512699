@mixin flex() {
  display: flex;
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin grid() {
  display: flex;
  flex-flow: column wrap;
  gap: var(--gap);

  @supports (grid-area: auto) {
    display: grid;
  }
}

@mixin grid-auto() {
  flex-flow: row wrap;

  --grid-auto: auto-fill;

  @supports (grid-area: auto) {
    /* max out at 4 columns */
    grid-template-columns: repeat(var(--grid-auto), minmax(var(--col), 1fr));
  }
}

@mixin grid-auto-fit() {
  --grid-auto: auto-fit;

  @include grid-auto;
}

@mixin grid-reset {
  grid-column: -1/1;
}

.grid {
  @include grid;
}

.grid-auto {
  @include grid-auto;
}

.grid-auto-fit {
  @include grid-auto-fit;
}

.grid-reset {
  @include grid-reset;
}

.r-flex {
  @include flex;
}

.c-flex {
  @include flex-column;
}