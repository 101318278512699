svg {
  fill: currentcolor;
}

.icon {
  --size: var(--icon-size);

  display: inline-block;
  vertical-align: middle;
  width: var(--size);
  height: var(--size);
  stroke: currentcolor;
  fill: none;
  stroke-width: 1.85;
  stroke-linecap: round;
  stroke-linejoin: round;

  .is-active > & {
    color: var(--link-higher);
  }

  a &,
  button & {
    color: currentcolor;
  }

  // slightly to top in a "button", pagination
  .btn &,
  .page-link & {
    margin-block-start: -0.1rem;
  }

  .nav-link & {
    --size: 1.65cap;
  }

  .disabled a & {
    color: var(--middleground);
  }

  // Sizes
  &--lg {
    --size: var(--icon-size-lg);
  }

  &--xl {
    --size: var(--icon-size-xl);
  }
}
