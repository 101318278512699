@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../font/" !default;
$slick-font-family: "slick" !default;
$slick-arrow-color: var(--middleground) !default;
$slick-dot-color: var(--middleground) !default;
$slick-dot-color-active: var(--foreground) !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 0.4rem !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick {
  &-track {
    padding-block-end: calc(var(--gap) * 1.5);
  }

  &-slider {
    display: grid;
    grid-template-areas:
      "la content ra"
      ". dots .";
    grid-template-rows: 1fr min-content;
    align-items: center;
  }

  // Content
  &-list {
    grid-area: content;
  }

  // Arrows
  &-prev {
    grid-area: la;
    background: transparent
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentcolor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='15 18 9 12 15 6'></polyline></svg>")
      50% 50% no-repeat;
  }

  &-next {
    grid-area: ra;
    background: transparent
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentcolor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='9 18 15 12 9 6'></polyline></svg>")
      50% 50% no-repeat;
  }

  &-arrow {
    width: calc(var(--gap) * 2.2);
    height: calc(var(--gap) * 2.2);
    z-index: 1000;
    text-indent: -10rem;
    overflow: hidden;
    border: none;

    @extend .btn-link;

    [aria-disabled] {
      cursor: not-allowed;
      user-select: none;
    }
  }

  // Dots
  &-dots {
    grid-area: dots;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        border: 0;
        background-color: var(--middleground);
        border-radius: 100%;
        display: block;
        height: calc(var(--gap) * 0.4);
        width: calc(var(--gap) * 0.4);
        outline: none;
        line-height: 0px;
        font-size: 0;
        padding: 5px;
        cursor: pointer;
      }

      &.slick-active button {
        background-color: $slick-dot-color-active;
        opacity: $slick-opacity-default;
      }
    }
  }
}
